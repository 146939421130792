<template>
  <page-setup>
    <v-container fluid>
      <v-row dense>
        <v-col class="d-flex align-center">
          <div class="text-h5 text-color-main font-weight-black my-4 mr-10">
            {{ title }}
          </div>
          <!-- <chat-group-cards class="d-flex"></chat-group-cards> -->
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <message-component></message-component>
        </v-col>
      </v-row>
    </v-container>
  </page-setup>
</template>

<script>
// const ChatGroupCards = () => import("../miscellaneous/ChatGroupCards.vue");
const MessageComponent = () => import("../components/MessageComponent.vue");

export default {
  components: {
    // ChatGroupCards,
    MessageComponent
  },
  data: () => ({
    title: "Chat"
  })
};
</script>
